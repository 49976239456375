<template>
  <v-dialog justify="center" :value="value" persistent max-width="300">
    <v-card color=#fafafa>
      <section class="ma-0 pa-6">
        <v-row justify="center">
          <v-icon x-large color="red">mdi-trash-can-outline</v-icon>
        </v-row>
        <v-row justify="center">
          <h3>
            {{action}} {{selected && type !== 'Contract' ? selected.length : ''}}
            {{selected && selected.length > 1 && type !== 'Contract' ? `${type}s` : type }}?
          </h3>
        </v-row>
        <v-row justify="center">
          <v-btn height="40" class="elevation-1" @click.stop="$emit('update:value', false)">
            No
          </v-btn>
          <v-btn color="#0c67a5" height="40" class="white--text ml-2"
            @click="confirm">Yes
          </v-btn>
        </v-row>
      </section>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: 'ConfirmDialog',

  props: [
    'value',
    'selectedItem',
    'selected',
    'action',
    'type',
    'copy',
    'remove'
  ],

  methods: {
    confirm () {
      this.action === 'Delete' ? this.remove(this.selectedItem) : this.copy()
      this.$emit('update:value', false)
    }
  }
}
</script>
